<template>
  <div id="dashboard-nurse">
    Dashboard Nurse
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>
